<template>
    <div class="container">
        <FilterBlock @search="search" />
        <UpDate v-model:upDateRow="upDateRow" @onUpdateSuccess="getTabelData" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'enabled'">
                        <a-switch
                            :checked="record.enabled"
                            @change="enabled => changeSwitch(record.enabled, record.id)"
                        />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a v-perm="'btn:primary-uom-edit'" @click="upDateRow = record">编辑</a>
                            <a v-perm="'btn:primary-uom-delete'" @click="deleteRow(record.id)">
                                删除
                            </a>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-space direction="horizontal" :size="8">
                            <a-button
                                type="primary"
                                v-perm="'btn:primary-uom-add'"
                                @click="upDateRow = {}"
                            >
                                新增
                            </a-button>
                        </a-space>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDate from "./upDate";
import { confirmModal, showMessage } from "@/utils/common";
import { apiPrimaryUnitList, apiPrimaryUnitEnable, apiDeletePrimaryUnit } from "@/api/generalData";
export default defineComponent({
    components: {
        FilterBlock,
        UpDate,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "单位编码",
                dataIndex: "code",
            },
            {
                title: "单位名称",
                dataIndex: "name",
            },
            {
                title: "单位类型",
                dataIndex: "typeDesc",
            },
            {
                title: "启用状态",
                dataIndex: "enabled",
                key: "enabled",
                width: 100,
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 120,
            },
        ];

        const state = reactive({
            showUploadDataModal: false,
            upDateRow: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiPrimaryUnitList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList;
            }
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const changeSwitch = (enabled, id) => {
            const type = enabled ? "warning" : "info";
            const content = enabled
                ? "单位禁用后，之后将无法在业务流程中使用该单位信息，是否确认禁用？"
                : "单位启用后，该单位可以在业务流程中使用，是否确认启用？";
            confirmModal({
                type,
                content,
                onOk: async () => {
                    let res = await apiPrimaryUnitEnable(id);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const deleteRow = id => {
            confirmModal({
                type: "warning",
                content: "单位删除后将无法恢复，是否确认删除该单位？",
                onOk: async () => {
                    const {
                        pagination: { current },
                        data,
                    } = state;
                    let res = await apiDeletePrimaryUnit([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        if (current != 1 && data.length == 1) {
                            state.pagination.current--;
                        }
                        getTabelData();
                    }
                },
            });
        };

        return {
            columns,
            ...toRefs(state),
            getTabelData,
            changeSwitch,
            search,
            paginationChange,
            deleteRow,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
.container {
    // display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>

<template>
    <a-modal
        v-model:visible="visible"
        :title="`${upDateRow?.id ? '编辑' : '新增'}主计量单位`"
        @cancel="handleCancel"
        width="600px"
        :footer="null"
        :destroyOnClose="true"
    >
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-row :span="24" class="margin-bottom">
                <a-col :span="24">
                    <a-form-item
                        label="单位编码"
                        name="code"
                        :rules="[
                            { required: true, message: '请输入单位编码' },
                            { validator: checkAllSpace },
                        ]"
                        style="margin-bottom: 20px"
                    >
                        <a-form-item>
                            <div class="contant-item">
                                <a-input
                                    style="width: 73%; margin-right: 20px"
                                    :disabled="sysCreate || Boolean(upDateId)"
                                    v-model:value="formData.code"
                                    :maxlength="100"
                                    showCount
                                ></a-input>
                                <a-checkbox
                                    :disabled="Boolean(upDateId)"
                                    v-model:checked="sysCreate"
                                    v-if="!upDateId"
                                >
                                    系统生成
                                </a-checkbox>
                            </div>
                        </a-form-item>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-form-item
                label="单位名称"
                name="name"
                :rules="[
                    { required: true, message: '请输入单位名称' },
                    { validator: checkAllSpace },
                ]"
            >
                <a-input v-model:value="formData.name" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item
                label="单位类型"
                name="type"
                :rules="[
                    { required: true, message: '请选择单位类型' },
                    { validator: checkAllSpace },
                ]"
            >
                <Select
                    v-model:value="formData.type"
                    :options="typeOptions"
                    labelKey="name"
                    valueKey="code"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiUpdatePrimaryUnit, apiPrimaryUnitUpdateIndexData } from "@/api/generalData";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import Select from "@/components/Select";

export default defineComponent({
    props: ["upDateRow"],
    emits: ["onUpdateSuccess", "update:upDateRow"],
    components: { Select },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            sysCreate: false,
            formRef: null,
            typeOptions: [],
            formData: {
                id: "",
                name: "",
                code: "",
                type: "",
            },
        });

        const getIndexData = async id => {
            let res = await apiPrimaryUnitUpdateIndexData(id);
            if (res.status === "SUCCESS") {
                const { typeCandidate, basPrimaryUom } = res?.data;
                state.typeOptions = typeCandidate;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = basPrimaryUom ? basPrimaryUom?.[key] : "";
                });
            }
        };

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            state.sysCreate = false;
            state.autoCreateCode = "";
            emit("update:upDateRow", null);
        };

        const onFinish = async () => {
            let res = await apiUpdatePrimaryUnit(state.formData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("onUpdateSuccess");
            }
        };

        const checkAllSpace = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const getOrder = async () => {
            let code = await getAutoCreateCode("BAS_PRIMARY_UNIT_OF_MEASUREMENT");
            state.autoCreateCode = code;
            state.formData.code = code;
        };

        watch(
            () => state.sysCreate,
            newVal => {
                if (newVal && !state.autoCreateCode) {
                    getOrder();
                } else {
                    state.formData.code = state.autoCreateCode;
                }
                if (!newVal) state.formData.code = "";
            }
        );

        watch(
            () => props.upDateRow,
            newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    getIndexData(props?.upDateRow?.id);
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkAllSpace,
        };
    },
});
</script>

<style lang="less" scoped>
.user-list {
    margin: 10px 0;
    .ant-checkbox-wrapper {
        flex-direction: row-reverse;
        color: #1890ff;
    }
    .operate-content {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>

<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="params.code" placeholder="请输入单位编码" />
            <a-input v-model:value="params.name" placeholder="请输入单位名称" />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
export default defineComponent({
    emits: ["search"],
    setup(props, { emit }) {
        const state = reactive({
            params: {
                code: undefined,
                name: undefined,
            },
        });

        const reset = () => {
            state.params.code = undefined;
            state.params.name = undefined;

            search();
        };

        const search = () => {
            let { code, name } = state.params;
            state.params.code = code?.trim();
            state.params.name = name?.trim();
            emit("search", state.params);
        };

        search();

        return {
            ...toRefs(state),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>

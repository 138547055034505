import HttpRequest from "@/utils/request";

const axios = new HttpRequest();

// 主计量单位
const apiPrimaryUnitList = data =>
    axios.request({ url: "/bas-primary-unit-of-measurement/find-page", method: "post", data });

const apiUpdatePrimaryUnit = data =>
    axios.request({
        url: `/bas-primary-unit-of-measurement/${data?.id ? "update" : "save"}`,
        method: "post",
        data,
    });

const apiPrimaryUnitEnable = id =>
    axios.request({ url: `/bas-primary-unit-of-measurement/change-enabled/${id}`, method: "post" });

const apiDeletePrimaryUnit = data =>
    axios.request({ url: "/bas-primary-unit-of-measurement/delete-by-ids", method: "post", data });

const apiPrimaryUnitUpdateIndexData = id =>
    axios.request({
        url: `/bas-primary-unit-of-measurement/get-add-or-update-init-data${id ? "/" + id : ""}`,
        method: "get",
    });

export {
    apiPrimaryUnitList,
    apiUpdatePrimaryUnit,
    apiPrimaryUnitEnable,
    apiDeletePrimaryUnit,
    apiPrimaryUnitUpdateIndexData,
};
